import router from "@/router"
import { scrollToElementId } from "@/utils/CommonUtils.js"
import { mapGetters } from "vuex"
import * as axiosHelper from "@/utils/axiosHelper"
import moment from "moment-timezone"

const mixin = {
	computed: {
		...mapGetters(["canEditCms", "authUser"]),
	},
	methods: {
		setCurrentTab: function (selectedTab, selectedTabId) {
			this.$store.dispatch("updateCurrentTab", selectedTab)
			location.hash = selectedTab
			if (selectedTabId !== undefined && selectedTabId != null && selectedTabId) {
				const elmnt = document.getElementById(selectedTabId)
				if (elmnt) elmnt.scrollIntoView()
			}
		},
		setDetailCurrentTab: function (selectedTab, selectedTabId) {
			this.$store.dispatch("updateDetailCurrentTab", selectedTab)
			location.hash = selectedTab
			if (selectedTabId !== undefined && selectedTabId != null && selectedTabId) {
				const elmnt = document.getElementById(selectedTabId)
				if (elmnt) elmnt.scrollIntoView()
			}
		},
		getDynamicPageParams() {
			return this.$route.query.subId
				? {
						params: {
							child_menu_id: this.$route.query.subId,
						},
					}
				: {
						params: {
							menu_id: this.$route.query.id,
						},
					}
		},
		isNewlyCreatedMenu(url) {
			if (url !== "" && url !== null && url !== undefined) {
				const fields = url.split("/")
				if (fields[1] === "custom-pages") {
					return true
				}
			} else {
				return false
			}
		},
		menuTitle(name) {
			if (name !== "" && name !== null && name !== undefined) {
				return name
					.split("-")
					.map((item) => item.charAt(0).toUpperCase() + item.substring(1))
					.join(" ")
			}
			return ""
		},
		showDetail(data, selectedId) {
			this.selectedEvent = Object.assign({}, data)
			this.activeItemId = data.id
			this.showDetails = true
			if (window.innerWidth < 768) {
				scrollToElementId(selectedId)
			}
		},
		fetchImage(imrUrl, width, height) {
			if (imrUrl) {
				return `${process.env.VUE_APP_URL}/imgproxy/hola/rs:fit:${width}:${height}:0/g:no/${window.btoa(imrUrl)}.jpg`
			}
			return "/img/SLMath/SLMath_default.png"
		},
		peoplePage(peopleId, pageName) {
			router.push({
				name: "people",
				params: { id: peopleId },
				query: { reDirectFrom: pageName },
			})
		},
		applyButtonRedirect(url) {
			location.href = url
		},
		videoDetailRedirect(workshop_id, schedule_id) {
			router.push({
				name: "video-detail",
				params: { id: workshop_id, scheduleId: schedule_id },
			})
		},
		fetchLocationOrigin() {
			return window.location.origin
		},
		showPreviewImages(index) {
			this.$el.querySelector(".v-viewer").$viewer.view(index)
		},
		fetchDetailEditUrlWithHashtag(url, hashtag) {
			return `${process.env.VUE_APP_LEGACY_MSRI_WEB_URL + url}/edit${hashtag}`
		},
		fetchDetailEditUrl(url, menuId) {
			return `${process.env.VUE_APP_LEGACY_MSRI_WEB_URL + url}/edit?menu_id=${menuId}`
		},
		fetchLegacyUrl(url) {
			return process.env.VUE_APP_LEGACY_MSRI_WEB_URL + url
		},
		fetchDoorDashUrl() {
			return process.env.VUE_APP_DOORDASH_URL
		},
		getDynamicPageBg(value) {
			return value.content_background_color ? `background: ${value.content_background_color};` : "background: #ffffff;"
		},
		validateCmsEditForm(data) {
			let valid = data.some((res) => {
				if (
					res.field_type !== "image" &&
					res.field_type !== "multi_image" &&
					res.field_type !== "toggle" &&
					res.field_type !== "accordion"
				) {
					if (!res.value) {
						return true
					}
				} else if (res.field_type === "accordion") {
					valid = res.accordionList.some((resAcc) => {
						if (!resAcc.accordionTitle) {
							return true
						}
						if (!resAcc.accordionDescription) {
							return true
						}
					})
					return valid
				}
			})
			return valid
		},
		eventPathTypeDynamic(type) {
			if (type === "Program") {
				return "programs-details"
			}
			if (type === "Workshop") {
				return "workshop-details"
			}
			if (type === "Seminar") {
				return "colloquia-seminars-details"
			}
			if (type === "Adjoint") {
				return "adjoint-program-details"
			}
			if (type === "MsriUp") {
				return "msri-up-program-details"
			}
			if (type === "SummerSchool") {
				return "summer-graduate-schools-details"
			}
			if (type === "SummerResearchWoman") {
				return "summer-research-in-mathematics-details"
			}
			if (type === "MayUp") {
				return "may-up-program-details"
			}
			return "public-event"
		},
		eventPathURLDynamic(type) {
			if (type === "Program") {
				return "/programs"
			}
			if (type === "Workshop") {
				return "/workshops"
			}
			if (type === "Seminar") {
				return "/seminars"
			}
			if (type === "Adjoint") {
				return "/adjoint"
			}
			if (type === "MsriUp") {
				return "/msri-up"
			}
			if (type === "SummerSchool") {
				return "/summer-schools"
			}
			if (type === "SummerResearchWoman") {
				return "/summer-research"
			}
			if (type === "MayUp") {
				return "/may-up"
			}
		},
		eventApiDynamic(type) {
			if (type === "Program") {
				return "programs"
			}
			if (type === "Workshop" || type === "GeneralEvent") {
				return "workshops"
			}
			if (type === "Seminar") {
				return "seminars"
			}
			if (type === "Adjoint") {
				return "adjoints"
			}
			if (type === "MsriUp") {
				return "msri_ups"
			}
			if (type === "SummerSchool") {
				return "summer_schools"
			}
			if (type === "SummerResearchWoman") {
				return "summer_research_woman"
			}
			if (type === "MayUp") {
				return "may_ups"
			}
		},
		eventStatusType(type) {
			if (type === "Approved") {
				return "px-2 mb-2 badge badge-success"
			}
			if (type === "Canceled" || type === "Cancelled") {
				return "px-2 mb-2 badge badge-danger"
			}
			return "px-2 mb-2 badge badge-dark"
		},
		signInWithOrcid() {
			location.replace(`${process.env.VUE_APP_API_URL}/auth/orcid`)
		},
		signInWithOrcidInNewTab() {
			localStorage.setItem("isOrcidSignUpFromRegistrationPage", "true")
			window.open(`${process.env.VUE_APP_API_URL}/auth/orcid`, "_blank")
		},
		checkNullAndEmptyValues(value) {
			if (value === null || value === "" || value === "null" || value === undefined) {
				return false
			}
			return true
		},
		replaceOptionsLunchOrder(value) {
			if (value) {
				const result = value
					.replaceAll(/:amount=>\d+/g, "")
					.replaceAll(/[:{}"[-]/g, "")
					.replaceAll(/]+/g, "")
					.replaceAll(/,+/g, " ")
					.replaceAll(/=>/g, " ")
					.replaceAll(/options/g, "Options : ")
					.replaceAll(/toppings/g, "/ Toppings : ")
					.replaceAll(/name/g, "")

				return result.trim()
			}
			return "--"
		},
		replaceCkEditorAssetRedirectURL(value) {
			const result = value.replaceAll(
				/(src="|href=")\/ckeditor_assets\//g,
				`$1${process.env.VUE_APP_URL}/ckeditor_assets/`,
			)
			return result.trim()
		},
		customLabelInstitution({ name, department, street, city, state, zipcode, country_name }) {
			const parts = [name]
			;[department, street, city, state, zipcode, country_name].forEach((part, index) => {
				if (part?.trim()) {
					parts.push(part.trim())
				}
			})

			return parts.join(", ")
		},
		canAccessPublicAndAdmin() {
			if (
				this.canEditCms ||
				(this.authUser && Number.parseInt(this.authUser.person_id) === Number.parseInt(this.$route.params.id))
			) {
				return true
			}
			return false
		},
		checkExternalEventRedirect(external_url, is_external_url) {
			if (external_url && is_external_url) {
				window.location.href = external_url
				return true
			}
			return false
		},
		checkBoolean(value) {
			if (value === true || value === "true") {
				return "Yes"
			}
			return "No"
		},
		checkValidateStatus(status) {
			if (status) {
				if (status === 403 || status === 401) {
					axiosHelper.renderLogin()
				} else {
					return false
				}
			} else {
				return false
			}
		},
		checkIfOtherGenderSelected(value) {
			if (value) {
				if (value === 4 || value === "4") {
					return true
				}
				return false
			}
			return false
		},
		checkIfHighestDegreeOtherSelected(value) {
			if (value) {
				if (value !== 4 && value !== "4") {
					return true
				}
				return false
			}
			return true
		},
		checkIfHighestDegreeHabilitationSelected(value) {
			if (value) {
				if (value === 5 || value === "5") {
					return true
				}
				return false
			}
			return false
		},
		checkIfEmploymentTypeSelectedRetiredOthers(value) {
			if (value) {
				if (value !== 6 && value !== "6") {
					return true
				}
				return false
			}
			return true
		},
		checkIfEmploymentTypeSelectedFacultyMember(value) {
			if (value) {
				if (value === 1 || value === "1") {
					return true
				}
				return false
			}
			return false
		},
		fetchSelectedEvent(id, tagId, eventType) {
			this.$http.get(`${process.env.VUE_APP_API_URL}/${eventType}/${id}/show_event`).then((res) => {
				if (res?.data) {
					this.showDetail(res.data, tagId)
				}
			})
		},
		dynamicSummerProgramsTabTitle(currentEventList, upcomingEventList) {
			if (currentEventList.length > 0 && upcomingEventList.length > 0) {
				return "Current and Upcoming"
			}
			if (currentEventList.length <= 0 && upcomingEventList.length > 0) {
				return "Upcoming"
			}
			if (currentEventList.length > 0 && upcomingEventList.length <= 0) {
				return "Current"
			}
			return "Upcoming"
		},
		videoViewAccess(data) {
			if (!this.canEditCms) {
				if (data.consent === true && data.not_recorded === false && data.technical_difficulty === false) {
					return true
				}
				return false
			}
			return true
		},
		checkIfEmeritiTrusteesCommitteeSelected(value) {
			if (value) {
				if (value === "Emeriti Trustees") {
					return true
				}
				return false
			}
			return false
		},
		checkIfCountrySelectedUnitedStates(value) {
			if (value) {
				if (value === 1 || value === "1") {
					return true
				}
				return false
			}
			return false
		},
		formatGeneralEventTime(startTime, endTime) {
			const startMoment = moment.tz(startTime, "America/Los_Angeles")
			const endMoment = moment.tz(endTime, "America/Los_Angeles")

			const startFormat = startMoment.format("MMMM Do, YYYY (hh:mmA z")
			const endFormat = endMoment.format("hh:mmA z)")
			return `${startFormat} - ${endFormat}`
		},
		formatDate(date) {
			const d = new Date(date)
			const day = String(d.getDate()).padStart(2, "0")
			const month = String(d.getMonth() + 1).padStart(2, "0")
			const year = String(d.getFullYear())
			return `${year}-${month}-${day}`
		},
		getStaticPermalinkURL() {
			return "/news-and-events/"
		},
	},
}

export default mixin
