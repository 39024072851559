import { render, staticRenderFns } from "./FooterEdit.vue?vue&type=template&id=fb3fb22a&scoped=true"
import script from "./FooterEdit.vue?vue&type=script&lang=js"
export * from "./FooterEdit.vue?vue&type=script&lang=js"
import style0 from "./FooterEdit.vue?vue&type=style&index=0&id=fb3fb22a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb3fb22a",
  null
  
)

export default component.exports